import uuid from 'uuid';
import { parse } from 'query-string';
import { UserService } from '../../../services/user-service';
import { affiliate } from '../../../helpers/main';
import { extractIntroducerDataFromUrl } from '../../../helpers/introducer';
import { createAction } from '../../utils';
import * as ACCOUNT from './action-types';

export const createShadowUser = () => async dispatch => {
  if (UserService.hasShadowUser()) {
    return;
  }

  const { client_customer_id: clientCustomerId, client_scheme_id: clientSchemeId } = parse(window.location.search);
  const introducerData = extractIntroducerDataFromUrl();
  const firebaseUserID = UserService.getFirebaseToken();

  const userData = {
    affiliateSource: affiliate ?? 'fhc',
    shadowId: uuid.v1(),
    ...introducerData.userData,
    ...(clientCustomerId ? { clientCustomerId } : {}),
    ...(clientCustomerId ? { clientSchemeId: clientSchemeId?.toLowerCase() === 'leaver' ? null : clientSchemeId } : {}),
    ...(firebaseUserID ? { firebaseToken: firebaseUserID } : {})
  };

  const response = await dispatch(
    createAction(
      {
        request: ACCOUNT.CREATE_SHADOW_USER_REQUEST,
        success: ACCOUNT.CREATE_SHADOW_USER_SUCCESS,
        failure: ACCOUNT.CREATE_SHADOW_USER_FAILURE
      },
      api => api.users.createUser
    )(userData)
  );

  UserService.setUserShadowId(response.shadowId);

  return response;
};

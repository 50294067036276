import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { INPUT_TYPES } from '../helpers/constants';
import { parseDefaultValue } from '../helpers/parse-default-value';

const mapExistingValueByType = ({ existingValue, type, ...props }) => {
  if (isNil(existingValue)) return;

  if (type === INPUT_TYPES.checkbox) {
    // if the type is checkbox, we need to map the existing value to the items to exclude values that are not in the items list
    return props.items.reduce((acc, item) => {
      if (existingValue[item.name]) {
        acc[item.name] = existingValue[item.name];
      }

      return acc;
    }, {});
  }

  return existingValue;
};

export const withValue = compose(
  withState('value', 'setValue', mapExistingValueByType),
  withHandlers({
    setFormValue: ({ setFormValues, ...props }) => setFormValues(props)
  }),
  lifecycle({
    async componentDidMount() {
      const {
        type,
        value,
        identifier,
        setValue,
        userAttributes,
        defaultValue,
        setFormValue,
        validateValue,
        additionalValues,
        setSubmitAdditionalValues
      } = this.props;

      let inputVal = value;

      //here we set calculated default value if there is no value in user attributes
      if (!inputVal && !isNil(defaultValue)) {
        inputVal = await parseDefaultValue(userAttributes, defaultValue, { identifier });

        setValue(inputVal);
      }

      // For inputs, that don't have a value in it - we do not need to set the form value and validate it
      if (
        ![
          INPUT_TYPES.groupParamsMarketingList,
          INPUT_TYPES.groupParams,
          INPUT_TYPES.button,
          INPUT_TYPES.infoButton
        ].includes(type)
      ) {
        setFormValue(inputVal);
        validateValue(inputVal);
      }

      if (additionalValues) {
        setSubmitAdditionalValues(additionalValues);
      }
    }
  })
);

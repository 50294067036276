import React, { useCallback } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import styles from '../../styles/components/input-label.module.scss';
import { toggleModal } from '../../store/reducers/modals';
import { InformerIcon } from '../icons/informer-icon';
import { pickProps } from '../../helpers/hocs/pick-props';
import { withStopPropagation } from '../../helpers/hocs/with-stop-propagation';
import { Image } from '../images/image';
import { withRequiredIndicator } from './required-indicator';
import { Explainer } from './form-explainer';

export const Informer = compose(
  connect(null, { toggleModal }),
  withHandlers({
    onClick:
      ({ data, toggleModal }) =>
      () => {
        toggleModal(true, 'help', { buttonEnabled: false, ...data });
      }
  }),
  withStopPropagation('onClick'),
  pickProps(['onClick', 'className', 'ariaLabel'])
)(InformerIcon);

Informer.displayName = 'Informer';

const PureLabel = React.memo(({ htmlFor, withPreventingDefault, className, children }) => {
  // Prevents multiple updates using in a combination with external parent click, such as withOptionRowClick().
  const preventDefault = useCallback(
    e => {
      if (withPreventingDefault) {
        e.preventDefault();
      }
    },
    [withPreventingDefault]
  );

  return (
    <label htmlFor={htmlFor} onClick={preventDefault} className={classnames(styles.label, className)}>
      {children}
    </label>
  );
});

PureLabel.displayName = 'PureLabel';

const LabelWithRequiredIndicator = withRequiredIndicator(PureLabel);

export const Label = React.memo(({ htmlFor, withPreventingDefault, informer, className, isRequired, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classnames({ [styles['no-informer']]: !informer })}>
        {informer && (
          <Informer ariaLabel={`More information about ${children}`} className={styles.informer} data={informer} />
        )}
      </div>
      <div className={className}>
        <LabelWithRequiredIndicator
          htmlFor={htmlFor}
          isRequired={isRequired}
          withPreventingDefault={withPreventingDefault}
        >
          {children}
        </LabelWithRequiredIndicator>
      </div>
    </div>
  );
});

Label.displayName = 'Label';

export const InputLabel = React.memo(({ layout, label, children, ...props }) => (
  <div className={classnames(styles.container, layout)}>
    <Label {...props}>{label}</Label>
    {children}
  </div>
));

InputLabel.displayName = 'InputLabel';

const InputSubLabel = React.memo(({ subLabel }) => <span className={styles['sub-label']}>{subLabel}</span>);

InputSubLabel.displayName = 'InputSubLabel';

export const InputOptionLabel = React.memo(
  ({ layout, label, subLabel, image, imageAlt, children, explainer, styling, className, ...props }) => (
    <>
      <div className={classnames(styles.container, layout, styling)}>
        <div className={styles['label-container']}>
          {image && <Image src={image} title={imageAlt ?? label} className={styles.image} />}
          <Label className={classnames(styles['option-label'], className)} {...props}>
            <span>{label}</span>
            {subLabel ? <InputSubLabel className={styles['sub-label']} subLabel={subLabel} /> : null}
          </Label>
        </div>
        {children}
      </div>
      {explainer ? <Explainer explainerId={`${props.htmlFor}_explainer`}>{explainer}</Explainer> : null}
    </>
  )
);

InputOptionLabel.displayName = 'InputOptionLabel';

import React from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/inputs/checkbox-group.module.scss';
import { InputTextarea as Textarea } from '../textarea';
import { InputGroup } from '../input-group';
import { withContainer } from '../input-field';
import { InputCheckbox } from './checkbox';

export const CheckboxGroup = React.memo(({ onChange, onTextChange, className, value, styling, items = [] }) => {
  return (
    <InputGroup heir className={classnames(styles.wrapper, className)}>
      {items.map(item => (
        <Checkbox
          key={item.name}
          value={value}
          data={item}
          onChange={onChange}
          onTextChange={onTextChange}
          styling={styling}
        />
      ))}
    </InputGroup>
  );
});

const CheckboxComponent = React.memo(({ data, onChange, onTextChange, getValueByName, styling }) => {
  const { label, subLabel, image, imageAlt, name, explainer, type, maxLength, placeholder, helpModal, value } = data;

  const { text, checked } = getValueByName(name);

  return (
    <>
      <InputCheckbox
        label={label}
        subLabel={subLabel}
        image={image}
        imageAlt={imageAlt}
        informer={helpModal}
        explainer={explainer}
        checked={checked}
        onChange={onChange({ name, boundValue: value, label })}
        className={classnames(styles.unchecked, { [styles.checked]: checked })}
        styling={styling}
      />
      {checked && type && (
        <Textarea placeholder={placeholder} maxLength={maxLength} value={text} onChange={onTextChange(name)} />
      )}
    </>
  );
});

const Checkbox = compose(
  lifecycle({
    componentDidMount() {
      const { data, value = {}, onChange } = this.props;
      const { name, additionalValueOnFalse, label } = data;
      //This part is not working properly

      //Initiate value that should be sent even if checkbox isn't selected
      if ((!value[name] || !value[name].checked) && additionalValueOnFalse) {
        onChange({ name, boundValue: additionalValueOnFalse, label })();
      }
    }
  }),
  withHandlers({
    getValueByName:
      ({ value = {} }) =>
      name => {
        return (
          //Initiate 'text' and 'checked' if no name in value.
          //To prevent changing state from uncontrolled to controlled
          value[name] ?? {
            text: '',
            checked: false
          }
        );
      }
  })
)(CheckboxComponent);

export const InputCheckboxGroup = withContainer(CheckboxGroup);
